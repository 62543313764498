<template>
  <div class="adsAndOffers">
    <section class="adsAndOffers-filtering-area mb-2">
      <b-row align-v="center">
        <b-col lg="4">
          <div class="adsAndOffers-filtering-area__filter-title mb-1">فلتر حسب:</div>
        </b-col>

        <b-col
          lg="4"
          md="6"
          xs="6"
          class="adsAndOffers-filtering-area__filter-by-activity-container d-flex w-100"
        >
          <div class="adsAndOffers-filtering-area__activity-filter-title mr-1">
            اسم الفعالية:
          </div>
          <ek-input-select
            clearable
            :options="activitiesList"
            v-model="filter.activity"
            name="filterStartDate"
            class="adsAndOffers-filtering-area__activity-filter-input"
          />
        </b-col>

        <b-col
          lg="4"
          md="6"
          xs="6"
          class="adsAndOffers-filtering-area__filter-by-ads-status-container d-flex"
        >
          <div class="adsAndOffers-filtering-area__status-filter-title mr-1">
            حالة الإعلان:
          </div>
          <ek-input-select
            clearable
            :options="adsStatusList"
            v-model="filter.status"
            name="statusFtiler"
            class="adsAndOffers-filtering-area__status-filter-input"
          />
        </b-col>
      </b-row>
    </section>
    <b-row>
      <b-col
        cols="12"
        lg="3"
        md="6"
        v-for="ads in adsList.filter(filterAds)"
        :key="ads.id"
      >
        <b-card no-body tag="article" class="ads-card mb-2">
          <b-card-body class="p-1">
            <div
              class="ads-card__header d-flex justify-content-between align-items-center"
            >
              <div class="ads-card__title">{{ ads.name }}</div>

              <StatusBadge
                :statusList="adsStatus"
                :selectedStatusNumber="ads.dashResponse"
              />
            </div>

            <div class="ads-card__date-range mt-1">
              {{ formatAdsDate(ads.startDate, ads.endDate) }}
            </div>

            <b-img
              :src="$store.getters['app/domainHost'] + '/' + ads.imageUrl"
              class="ads-card__img mt-1 obj-cover"
            ></b-img>

            <div class="ads-card__ads-content mt-1">
              {{ ads.content }}
            </div>
          </b-card-body>
          <b-button
            variant="primary"
            class="d-block w-100 details-button p-1"
            @click="navigateToAdsDetails(ads.id)"
            >تفاصيل</b-button
          >
        </b-card>
      </b-col>
      <b-col cols="12" class="d-flex justify-content-center">
        <ek-pagination :items="adsList" v-model="filterdAds" :pageLength="12" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import StatusBadge from "@global/components/StatusBadge";

export default {
  components: {
    StatusBadge,
  },

  computed: {
    ...mapGetters(["adsList"]),
    ...mapState({
      activitiesList: ({ activities }) => activities.activities,
      adsStatus: (state) => state.adsAndOffers.adsStatus,
    }),
  },
  data: () => ({
    filterdAds: [],
    activityId: null,
    adsStatusList: [
      { id: 3, name: "فعال" },
      { id: 4, name: "منتهي" },
    ],
    filter: {
      activity: "",
      status: "",
    },
  }),
  created() {
    this.getAdsList();
  },
  methods: {
    ...mapActions(["getAdsList"]),
    ...mapMutations(["Set_Filter_Dto", "Reset_Ads_Dto"]),
    filterAds(el) {
      return (
        (el.senderId == this.filter.activity || !this.filter.activity) &&
        (el.dashResponse == this.filter.status || !this.filter.status)
      );
    },

    formatAdsDate(startDate, endDate) {
      return `${new Date(startDate).toLocaleDateString()}-${new Date(
        endDate
      ).toLocaleDateString()}`;
    },
    navigateToAdsDetails(id) {
      this.$router.push(`/admin/adsAndOffers/${id}`);
    },
    beforeDestroy() {
      this.$store.commit("Reset_Search_Dto");
    },
  },
};
</script>

<style lang="scss" scoped>
.adsAndOffers-filtering-area {
  align-items: center;

  @media only screen and (max-width: 780px) {
    flex-direction: column;
  }

  &__filter-title {
    font-weight: bold;
    font-size: 1.2rem;

    color: #6e6b7b;
  }

  &__filter-container {
    display: flex;
    gap: 2rem;
  }

  &__filter-by-activity-container {
    display: flex;
  }

  &__activity-filter-title {
    margin-top: 1.4rem;
    font-size: 1.2rem;

    color: #6e6b7b;
  }

  &__activity-filter-input {
    flex: 1;
  }

  &__filter-by-ads-status-container {
    display: flex;
  }

  &__status-filter-title {
    margin-top: 1.4rem;

    font-size: 1.2rem;

    color: #6e6b7b;

    @media only screen and (max-width: 780px) {
      margin-left: 0px;
    }
  }

  &__status-filter-input {
    flex: 1;
  }
}

.ads-card {
  &__title {
    font-weight: bold;
    font-size: 1.2rem;
    color: #5e5873;
  }

  &__date-range {
    font-weight: normal;
    font-size: 1.2rem;

    color: #5e5873;
  }

  &__img {
    width: 100%;
    border-radius: 6px;
    height: 190px;
  }

  &__ads-content {
    font-weight: normal;
    font-size: 1.2rem;

    color: #5e5873;
  }
}
</style>
